<!--
 * @Description: 已签约企业（市场部自行导入）
 * @Author: 琢磨先生
 * @Date: 2023-02-18 21:33:47
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-01-12 10:43:39
-->
<template>
  <el-card class="box query">
    <el-form :model="query" ref="query" class="no-mg-b" :inline="true">
      <el-form-item label="关键字">
        <el-input
          v-model="query.q"
          placeholder="公司名称"
          style="width: 300px"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" round @click="onSearch" icon="search"
          >查询</el-button
        >
        <excel-import></excel-import>
        <el-tooltip
          effect="dark"
          content="导出全部已签约公司数据"
          placement="bottom"
        >
          <el-button
            icon="Download" link type="primary"
            v-power="'admin/v1/company/qianyue/export'"
            @click="downloadExcel"
            :loading="downloading"
            >下载数据</el-button
          >
        </el-tooltip>
      </el-form-item>
    </el-form>
  </el-card>
  <el-card class="box data" v-loading="loading">
    <el-table :data="tableData.data" border stripe>
      <el-table-column
        label="公司名称"
        prop="companyName"
        mni-width="300"
      ></el-table-column>
      <el-table-column label="操作" width="150" >
        <template #default="scope">
          <el-popconfirm
            width="220"
            v-if="is_can_del"
            title="您确定要删除此数据？"
            @confirm="onDelete(scope.row)"
          >
            <template #reference>
              <el-button
                size="small"
                type="danger"
                link
                icon="delete"
                :loading="scope.row.deleting"
                >删除</el-button
              >
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="pageSizeChange"
      @current-change="pageCurrentChange"
      :current-page="query.pageIndex"
      :page-sizes="[20, 40, 60, 80, 100]"
      :page-size="query.pageSize"
      layout="total, sizes, prev, pager, next"
      :total="tableData.counts"
    ></el-pagination>
  </el-card>
</template>

<script>
import fileDownload from "js-file-download";
import ExcelImport from "./excel_import.vue";

export default {
  components: {
    ExcelImport,
  },
  data() {
    return {
      loading: false,
      downloading: false,
      query: {
        pageIndex: 1,
        pageSize: 20,
        sort: {
          order: "",
          prop: "",
        },
        q: "",
      },
      tableData: {
        counts: 0,
      },
      current_item: null,
      is_can_del: false,
    };
  },
  created() {
    this.is_can_del = this.$power("admin/v1/company/qianyue/del");
    this.loadData();
  },
  methods: {
    /**
     * 加载数据
     */
    loadData() {
      this.loading = true;
      //审核中
      this.query.status = 3;
      this.$http
        .post("/admin/v1/company/qianyue", this.query)
        .then((res) => {
          if (res.code == 0) {
            this.tableData = res.data;
          }
        })
        .finally(() => (this.loading = false));
    },
    /**
     * 搜索
     */
    onSearch() {
      this.query.pageIndex = 1;
      this.loadData();
    },
    /**
     * 排序
     */
    onSort(e) {
      if (e.prop) {
        this.query.sort = {
          order: e.order,
          prop: e.prop,
        };
        this.onSearch();
      }
    },

    /**
     * 分页页数更改
     */
    pageSizeChange(val) {
      this.query.pageSize = val;
      this.loadData();
    },

    /**
     * 分页页码更改
     */
    pageCurrentChange(val) {
      this.query.pageIndex = val;
      this.loadData();
    },

    /**
     * 删除
     */
    onDelete(item) {
      item.deleting = true;
      this.$http
        .get(`admin/v1/company/qianyue/del?id=${item.id}`)
        .then((res) => {
          if (res.code == 0) {
            if (this.query.pageIndex > 1) {
              if (this.tableData.data.length <= 1) {
                this.query.pageIndex--;
              }
            }
            this.loadData();
          }
        });
    },

    /**
     * 下载数据
     */
    downloadExcel() {
      this.downloading = true;
      this.$http
        .get("admin/v1/company/qianyue/export", {
          responseType: "blob",
        })
        .then((res) => {
          fileDownload(res, "已签约公司.xlsx");
        })
        .finally(() => {
          this.downloading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
